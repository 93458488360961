.vault-form .field-avatar { position:relative }
.vault-form .field-avatar input{ position:absolute; top:0; left:0; height:100%; cursor:pointer; opacity:0 }
.vault-form .field-avatar .avatar-load{
    position:absolute; top:0; left:0; right:0; bottom:0; background:rgba(255,255,255,.8)
}
.vault-form .field-avatar .avatar-load .MuiCircularProgress-root{ margin-left:8px; margin-top:8px }


.vault-form-edit .field-title .MuiOutlinedInput-notchedOutline,
.vault-form-edit .field-section .MuiOutlinedInput-notchedOutline,
.vault-form-edit .field-name .MuiOutlinedInput-notchedOutline,
.vault-form-edit .field-value .MuiOutlinedInput-notchedOutline,
.vault-form-edit .field-notes .MuiOutlinedInput-notchedOutline{
    border-color:transparent;
}

.vault-form-edit .Mui-disabled fieldset{border-color:transparent!important;}

.vault-form-edit .field-section .MuiOutlinedInput-input,
.vault-form-edit .field-name .MuiOutlinedInput-input,
.vault-form-edit .field-value .MuiOutlinedInput-input{
    padding: 3px 8px 4px 8px;
}

.vault-form-see{}
.vault-form-see .field-name .MuiOutlinedInput-input,
.vault-form-see .field-value .MuiOutlinedInput-input{
    padding:3px;
}
.vault-form-see .field-section .MuiOutlinedInput-input{
    padding:6px 3px 0 3px; font-size:14px;
}
.vault-form-see .field-value .MuiOutlinedInput-input{
    cursor:pointer;
}

.vault-form-see .field-avatar{ margin-right:14px }
.vault-form-see .field-avatar input{ display:none }

.vault-form-see .field-title .MuiOutlinedInput-input{ padding-left:5px; padding-top:2px;padding-bottom:2px }

.vault-form-see .field-title .MuiOutlinedInput-notchedOutline,
.vault-form-see .field-name .MuiOutlinedInput-notchedOutline,
.vault-form-see .field-section .MuiOutlinedInput-notchedOutline{ border-color:transparent!important; }
.vault-form-see .field-value .MuiOutlinedInput-notchedOutline{
    border-color:transparent!important; border-style: dashed!important; border-width:1px!important;
}
.vault-form-see .field-value:hover .MuiOutlinedInput-notchedOutline{
    border-color: #999!important;
}

.vault-form-see .field-value .MuiOutlinedInput-adornedEnd{
    padding-right:2px;
}.vault-form-see .field-value .MuiInputAdornment-positionEnd{
    visibility:hidden;
}
.vault-form-see .field-value .MuiInputAdornment-positionEnd .MuiButton-root{
    padding:1px 9px 0 9px; margin-left:4px; min-width:40px;
}
.vault-form-see .field-value:hover .MuiInputAdornment-positionEnd{
    visibility:visible;
}


.vault-form-see .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense{padding:0;}
.vault-form-see .notes-title {
    padding-right:5px;
}
.vault-form-see .field-notes .MuiOutlinedInput-input{
    padding:0 4px;
}
.vault-form-see .field-notes .MuiOutlinedInput-notchedOutline{ border-color:transparent!important; }

/* GRUPO (view) */
.group-view {}
.group-view .group-name{ font-weight: bold; font-size:20px; padding: 0 4px 0 4px  }
.group-view .field-section{ font-weight: bold; font-size:14px; padding: 4px  }
.group-view .field-name{ padding:3px; text-align: right; color: #777; font-size: 15px }
.group-view .field-right:hover{
    background: linear-gradient(180deg,
    rgba(0, 0, 0, 0) calc(50% - 0px),
    #DDD calc(50%),
    rgba(0, 0, 0, 0) calc(50% + 1px)
    );
}
.group-view .field-value{ font-size: 15px }
.group-view .field-value .text{ padding: 3px 4px; display:inline-block; background:white }
.group-view .field-action { background:white }
.group-view .field-action .MuiButton-root{ padding:1px 9px 0 9px; margin-left:4px; min-width:40px; line-height:20px }
.group-view .field-right .field-action{ display:none }
.group-view .field-right:hover .field-action{ display:block }

.group-view .field-value .type-color{
    display:inline-block; color:white; border-radius:3px; padding:0 3px; text-transform:uppercase
}

/* widget GField */
.gfield{  }
.gfield .gfield-left{ float:left; width:33.33% }
.gfield .gfield-right{ float:left; width:66.66% }
.gfield:after {content: "";display: table;clear: both;}


/* nestable */
.nestable-item .nestable-list, .nestable-item-copy .nestable-list,.nestable-item, .nestable-item-copy { margin-top:0!important; }
.nestable .nestable-list .nestable-item-name { padding:0; border-radius:3px; cursor:move;}